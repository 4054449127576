import React from "react";
import { SearchResult } from "@elastic/search-ui";

const CustomResultView = ({
  result,
  onClickLink,
}: {
  result: SearchResult;
  onClickLink: () => void;
}) => {
  // console.log(result);
  return (
    <li className="sui-result">
      <div className="sui-result__header">
        <h3>
          {/* Maintain onClickLink to correct track click throughs for analytics*/}
          <a onClick={onClickLink} href="#">
            {result.TITLE.raw}
          </a>
        </h3>
      </div>
      <div className="sui-result__body">
        {/* {JSON.stringify(result)} */}
        {/* use 'raw' values of fields to access values without snippets */}
        <div className="sui-result__image">
          <img src={result.IMAGES.raw[0]} alt="" />
        </div>
        <div
          className="sui-result__details"
          dangerouslySetInnerHTML={{ __html: result.DESCRIPTION.raw }}
        ></div>
        {/* Use the 'snippet' property of fields with dangerouslySetInnerHtml to render snippets */}
      </div>
    </li>
  );
};
export default CustomResultView;
