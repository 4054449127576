import { SearchDriverOptions } from "@elastic/search-ui";
import { connector } from "./api-connector";

export const searchProviderConfig = (search: string): SearchDriverOptions => ({
  searchQuery: {
    search_fields: {
      TITLE: {
        weight: 3,
      },
    },
    result_fields: {
      TITLE: {
        snippet: {},
      },
      IMAGES: {
        raw: {},
      },
      DESCRIPTION: {
        raw: {},
      },
      plot: {
        snippet: {},
      },
    },
    disjunctiveFacets: [],
    facets: {},
  },
  autocompleteQuery: {
    results: {
      resultsPerPage: 2,
      search_fields: {
        TITLE: {},
      },
      result_fields: {
        TITLE: {
          snippet: {
            size: 100,
            fallback: true,
          },
        },
      },
    },
    suggestions: {
      types: {
        results: { fields: ["TITLE.completion"] },
      },
      size: 4,
    },
  },
  apiConnector: ((st: string) => connector(st))(search),
  alwaysSearchOnInitialLoad: true,
});
