import { RequestState } from "@elastic/search-ui";
import ElasticsearchAPIConnector, {
  SearchRequest,
} from "@elastic/search-ui-elasticsearch-connector";
import { request } from "http";
import { getConfig } from "./config-helper";

export const connector = (searchType: string): ElasticsearchAPIConnector => {
  const middleware = (
    requestBody: SearchRequest,
    requestState: RequestState
  ) => {
    const sType = localStorage.getItem("type") || "hibrid";
    // const sType = searchType;
    console.log(sType);
    requestBody.query = undefined;

    switch (sType) {
      case "hibrid":
        addKnn(requestState, requestBody);
        addRank(requestState, requestBody);
        addTextSearch(requestState, requestBody);
        break;
      case "vector":
        addKnn(requestState, requestBody);
        break;
      default:
        addTextSearch(requestState, requestBody);
        break;
    }

    requestBody.highlight = undefined;
    requestBody.sort = undefined;
    return requestBody;
  };

  return new ElasticsearchAPIConnector(
    {
      host: "https://webwise-test.ent.us-central1.gcp.cloud.es.io/api/as/v1/engines/product-test/elasticsearch",
      index: "",
      connectionOptions: {
        headers: { Authorization: "Bearer private-od95ekeq8vqobto3khk7jzdv" },
      },
    },
    middleware
  );
};

const addTextSearch = (
  requestState: RequestState,
  requestBody: SearchRequest
) => {
  requestBody.query = {
    query_string: {
      query: `TITLE: ${requestState.searchTerm || "''"} OR DESCRIPTION: ${
        requestState.searchTerm || "''"
      } `,
    },
  };
};

const addRank = (requestState: RequestState, requestBody: SearchRequest) => {
  requestBody.rank = {
    rrf: {
      rank_constant: 20,
      window_size: requestState.resultsPerPage,
    },
  };
};

const addKnn = (requestState: RequestState, requestBody: SearchRequest) => {
  requestBody.knn = [
    {
      field: "ml.inference.TITLE.predicted_value",
      query_vector_builder: {
        text_embedding: {
          model_id: ".multilingual-e5-small_linux-x86_64",
          model_text: requestState.searchTerm || "''",
        },
      },
      k: 100,
      num_candidates: 100,
    },
    {
      field: "ml.inference.DESCRIPTION.predicted_value",
      query_vector_builder: {
        text_embedding: {
          model_id: ".multilingual-e5-small_linux-x86_64",
          model_text: requestState.searchTerm || "''",
        },
      },
      k: 100,
      num_candidates: 100,
    },
  ];
};
