import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";

const SearchTypeSelector: React.FC<SearchTypeSelectorProps> = ({
  onChange,
  value,
}) => {
  const onValueChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      sx={{ height: "50.5px" }}
      value={value}
      onChange={(event) => onValueChange(event)}
    >
      <MenuItem value={"hibrid"}>Hibrid Search</MenuItem>
      <MenuItem value={"vector"}>Vector Search</MenuItem>
      <MenuItem value={"text"}>Text Search</MenuItem>
    </Select>
  );
};

export default SearchTypeSelector;

export type SearchTypeSelectorProps = {
  onChange: (type: string) => void;
  value: string;
};
