import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import React, { useState } from "react";
import {
  ErrorBoundary,
  Facet,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch,
  Result,
  SearchProvider,
} from "@elastic/react-search-ui";
import {
  BooleanFacet,
  Layout,
  SingleLinksFacet,
  SingleSelectFacet,
  Sorting,
} from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import { getConfig } from "./config/config-helper";
import ElasticsearchAPIConnector, {
  SearchRequest,
} from "@elastic/search-ui-elasticsearch-connector";
import { RequestState, SearchDriverOptions } from "@elastic/search-ui";
import "./App.scss";
import { searchProviderConfig } from "./config/search-provider-config";
import CustomResultView from "./components/custom-result/CustomResultView";
import SearchTypeSelector from "./components/algorithm-selector/SearchTypeSelector";

const App = () => {
  const [searchType, seSearchType] = useState(
    localStorage.getItem("type") || "hibrid"
  );

  const config = searchProviderConfig(searchType);

  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
        {({ wasSearched }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={
                    <div className="search-header-wrapper">
                      <div className="search-header-wrapper-logo-selector">
                        <img
                          className="syncee-logo"
                          _ngcontent-vqu-c644=""
                          alt="Syncee logo"
                          ng-reflect-router-link="/"
                          src="https://beta.syncee.co/assets/images/logos/syncee-logo.svg"
                        ></img>
                        <SearchTypeSelector
                          onChange={(stype) => {
                            localStorage.setItem("type", stype);
                            seSearchType(stype);
                          }}
                          value={searchType}
                        ></SearchTypeSelector>
                      </div>

                      <SearchBox
                        autocompleteMinimumCharacters={3}
                        autocompleteResults={{
                          linkTarget: "_blank",
                          sectionTitle: "Results",
                          titleField: "title",
                          urlField: "url",
                          shouldTrackClickThrough: true,
                        }}
                        autocompleteSuggestions={true}
                        debounceLength={0}
                      />
                    </div>
                  }
                  sideContent={
                    <div>
                      <Facet
                        key={"1"}
                        field={"genre.keyword"}
                        label={"genre"}
                      />
                      <Facet
                        key={"2"}
                        field={"actors.keyword"}
                        label={"actors"}
                      />
                      <Facet
                        key={"3"}
                        field={"directors.keyword"}
                        label={"directors"}
                      />
                      <Facet key={"4"} field={"released"} label={"released"} />
                      <Facet
                        key={"4"}
                        field={"imdbRating"}
                        label={"imdb rating"}
                      />
                    </div>
                  }
                  bodyContent={<Results resultView={CustomResultView} />}
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
};

export default App;
